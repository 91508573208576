<template>
  <div id="app">
    <status-alert :color="statusColor" :shown.sync="statusShown">
      {{ statusMessage }}
    </status-alert>
    <router-view/>
  </div>
</template>

<script>
import Bus from './bus'
import StatusAlert from './components/StatusAlert'

export default {
  name: 'App',
  data () {
    return {
      statusMessage: '',
      statusShown: false,
      statusColor: 'error'
    }
  },
  components: {
    StatusAlert
  },
  created () {
    Bus.$on('alert', ({ color, message }) => {
      this.statusShown = true
      this.statusColor = color
      this.statusMessage = message
    })
  },
  mounted () {
    window.addEventListener('resize', this.resizeEvent)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeEvent)
  },
  methods: {
    resizeEvent () {
      Bus.$emit('resize')
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center; 
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
