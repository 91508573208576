import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'vizibill',
  storage: window.sessionStorage,
  async saveState (key, state, storage) {
    await storage.setItem(
      key,
      JSON.stringify(state)
    )
  },
  restoreState (key, storage) {
    let state = storage.getItem(key)
    if (state && JSON.parse(state)) {
      state = JSON.parse(state)
    }
    if (state) {
      return state
    }
  }
})

export default new Vuex.Store({
  state: {
    clientToken: "",
    currentBan: "",
    boss40Token: "",
    currentCharges: [],
    backgroundColor:'#fafafa',
    account: {},
    adminPayConsoleDetails: null
  },
  mutations: {
    SET_TOKEN(state, value){
      state.clientToken = value
    },
    SET_BAN(state,value){
      state.currentBan = value
    },
    SET_AUTH(state, value){
      state.boss40Token = value
    },
    SET_CHARGES(state, value){
      state.currentCharges = value
    },
    SET_BACKGROUND_COLOR(state, value){
      state.backgroundColor = value;
    },
    SET_ACCOUNT(state, value){
      state.account = value;
    },
    SET_ADMIN_PAY_CONSOLE_DETAILS(state, value){
      state.adminPayConsoleDetails = value;
    }
  },
  actions: {
    updateToken({ commit }, payload){
      commit('SET_TOKEN', payload)
    },
    updateBan({ commit }, payload){
      commit('SET_BAN', payload)
    },
    updateAuthToken({ commit }, payload){
      commit('SET_AUTH', payload)
    },
    updateCurrentCharges({ commit }, payload){
      commit('SET_CHARGES', payload)
    },
    updateBackgroundColor({commit},payload){
      commit('SET_BACKGROUND_COLOR', payload)
    },
    updateAccount({commit},payload){
      commit('SET_ACCOUNT', payload)
    },
    updateAdminPayConsoleDetails({commit},payload){
      commit('SET_ADMIN_PAY_CONSOLE_DETAILS', payload)
    }
  },
  getters:{
    backgroundColor:(state)=> state.backgroundColor,
    account:(state) => state.account,
    adminParams:(state) => state.adminParams
  },
  modules: {},
  plugins: [vuexLocal.plugin]
})