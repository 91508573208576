import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/editpaymentmethod',
    name: 'PaymentMethod',
    component: () => import(/* webpackChunkName: "editpaymentmethod" */ '../views/EditPaymentMethod.vue')
  },
  {
    path: '/createinvoice',
    name: 'CreateInvoice',
    component: () => import(/* webpackChunkName: "createInvoice" */ '../views/CreateInvoice.vue')
  },
  {
    path: '/tx3passwordreset',
    name: 'Tx3PasswordReset',
    component: () => import(/* webpackChunkName: "createInvoice" */ '../views/Tx3PasswordReset.vue')
  },
  {
    path: '/addcard',
    name: 'AddCard',
    component: () => import(/* webpackChunkName: "addCard" */ '../views/AddCard.vue')
  },
  {
    path: '/vizibill/paymentconsole',
    name: 'PaymentConsole',
    component: () => import(/* webpackChunkName: "MyAccount40" */ '../views/Vizibill/PaymentConsole.vue')
  },
  {
    path: '/vizibill/paynow',
    name: 'AdminPayNow',
    component: () => import(/* webpackChunkName: "MyAccount40" */ '../views/Vizibill/AdminPayNow.vue'),
    props: true
  },
  {
    path: '/vizibill/payall',
    name: 'AdminPayAll',
    component: () => import(/* webpackChunkName: "MyAccount40" */ '../views/Vizibill/AdminPayAll.vue')
  },
  {
    path: '/myaccount40/addcard',
    name: 'MyAccount40AddCard',
    component: () => import(/* webpackChunkName: "MyAccount40" */ '../views/MyAccount40/AddCard.vue'),
    meta: {
      title: 'MyAccount'
    }
  },
  {
    path: '/myaccount40/addach',
    name: 'MyAccount40ACH',
    component: () => import(/* webpackChunkName: "MyAccount40" */ '../views/MyAccount40/AddACH.vue'),
    meta: {
      title: 'MyAccount'
    }
  },
  {
    path: '/myaccount40/paynow',
    name: 'MyAccount40PayNow',
    component: () => import(/* webpackChunkName: "MyAccount40" */ '../views/MyAccount40/PayNow.vue'),
    meta: {
      title: 'MyAccount'
    }
  },
  {
    path: '/myaccount40/payall',
    name: 'MyAccount40PayAll',
    component: () => import(/* webpackChunkName: "MyAccount40" */ '../views/MyAccount40/PayAll.vue'),
    meta: {
      title: 'MyAccount'
    }
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: () => import('../views/policies/PrivacyPolicy.vue'),
    meta: {
      title: 'MyAccount'
    }
  },
  {
    path: '/termsconditions',
    name: 'Terms & Conditions',
    component: () => import('../views/policies/TermsConditions.vue'),
    meta: {
      title: 'MyAccount'
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

export default router