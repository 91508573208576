<template>
  <v-snackbar id="statusAlert" v-model="localShown" top :color="color">
    <slot />
    <v-btn flat @click="$emit('update:shown', false)">Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'StatusAlert',
  props: {
    shown: Boolean,
    color: String
  },
  data () {
    return {
      timeout: null,
      localShown: false
    }
  },
  watch: {
    shown (newVal) {
      this.localShown = newVal
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.localShown = false
        this.$emit('update:shown', false)
      }, 5000)
    }
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
  }
}
</script>
